import React, { useEffect } from 'react';
import Helmet from 'react-helmet';

import App from '../../../components/layout/App';
import JoinTheTeamPanel from '../../../components/careers/JoinTheTeamPanel';
import CulturePanel from '../../../components/careers/CulturePanel';

const CareersPage = (props) => {
  useEffect(() => {
    if (window._grnhse) {
      window._grnhse.load();
    } else {
      setTimeout(() => {
        if (window._grnhse) {
          window._grnhse.load();
        }
      }, 2000);
    }
  }, []);

  return (
    <App displayGettingStartedPanel={false}>
      <div className="component careers-page">
        <Helmet title="Careers – ThinkReservations">
          <meta
            name="description"
            content="Join our team of passionate, experienced professionals and help our customers succeed!"
          />
        </Helmet>

        <JoinTheTeamPanel />
        <div id="grnhse_app"></div>
        <CulturePanel />
      </div>
    </App>
  );
};

export default CareersPage;
