import React, { Component } from 'react';

import './index.css';

class JoinTheTeamPanel extends Component {
  render() {
    return (
      <div className="component join-the-team-panel">
        <div className="join-the-team-panel-inner-wrapper">
          <h1>Join the team</h1>
          <p>
            ThinkReservations is made of a group of passionate people eager to
            help our customers grow their business. Come learn how you can be a
            part of our team.
          </p>
        </div>
      </div>
    );
  }
}

export default JoinTheTeamPanel;
