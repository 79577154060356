import React, { Component } from 'react';

import './index.css';

class CulturePanel extends Component {
  render() {
    return (
      <div className="component culture-panel">
        <div className="values">
          <div className="value">
            <div className="value-name">Be purposeful</div>
            <div className="value-description">
              Work on exciting projects that have measureable results for
              customers.
            </div>
          </div>
          <div className="value">
            <div className="value-name">Be independent</div>
            <div className="value-description">
              Working remotely is built into the ThinkReservations culture!
            </div>
          </div>
          <div className="value">
            <div className="value-name">Have fun!</div>
            <div className="value-description">
              Our team loves working for ThinkReservations and we are sure you
              will too!
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CulturePanel;
